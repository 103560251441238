import { formatMuitlData } from 'public/src/pages/components/filter_new/common/format'
import { TAG_ITEM_TYPE, FILTER_BAR_MAP, FILTER_RESULT_TYPE_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'
import { handleParamsByTagTsp } from 'public/src/pages/components/FilterBar/utils/tsp.js'
import { handleParamsByTagAttrs } from 'public/src/pages/components/FilterBar/utils/attr.js'
import { handleParamsByTagBrand } from 'public/src/pages/components/FilterBar/utils/brand.js'
import { safeSplit } from 'public/src/pages/components/FilterBar/utils/index.js'

// 是否自定义属性
export function getIsCustom (attr, key = 'data') { 
  return attr?.[key]?.some(item => item[key]?.length > 0)
}

// 获取当前选中的属性
export function getActiveCustomList (attrs, queryParams) {
  return [
    ...safeSplit(queryParams?.brand_ids, ','),
    ...safeSplit(queryParams?.attr_node_ids, '-'),
    ...safeSplit(queryParams?.tsp_ids, ',')?.map(id => id.split('_')[1]),
  ]
}

export function formatCustomList (attrs, filterBar) {
  if (attrs && !attrs.length) return []

  // 只保留有数据的项
  attrs.forEach(attr => {
    if (attr.show_group) {
      attr.data = attr.groups
      attr.dataType = 'img'
      return
    }
    attr.dataType = 'mulit'
    attr.data = attr.attr_values
    attr.data = formatCustomList(attr.data, filterBar)
    // 处理dataType
    attr.data.forEach(item => {
      item.data.forEach(obj => {
        if (obj.nodeType == 7) {
          obj.dataType = 'tsp'
          obj.label_img = obj.attr_image
        } else if (obj.attr_image) {
          obj.dataType = 'img'
          obj.label_img = obj.attr_image
        }
      })
    })
  })

  return formatMuitlData({
    attr_id: 'id',
    attr_name: 'name',
    data: 'data',
    dataItem: {
      attr_id: 'id',
      attr_value: 'label',
      group_name: 'label',
      group_image: 'label_img',
      nodeId: 'mix_value',
    }
  }, attrs, false)
}

// 过滤自定义筛选
export function filterCustomByNodeType (attrs = []) {
  return attrs?.filter(attr => attr.nodeType == 4) || []
}

export function getCustomList ({ filterBar, queryParams, filterBarType, queryParamsKey, showNum = true }) {
  if (!filterBar?.filterData?.filterAttrs?.length) return []
  
  let list = filterCustomByNodeType(filterBar?.filterData?.filterAttrs)
  list = formatCustomList(list, filterBar)
  const activeList  = getActiveCustomList(list, queryParams, filterBar, queryParamsKey)
  return list.map(attr => {
    const selectedIds = [] // 被选中的属性值个数
    const childList = getIsCustom(attr) ? attr.data.reduce((acc, cur) => acc.concat(cur.data), []) : attr.data
    
    childList.forEach(item => {
      activeList?.includes(item.nodeId) && selectedIds.push(item.nodeId)
    })
    const selectedNum = selectedIds.length
    let label = (selectedNum && showNum) ? `${attr.name}(${selectedNum})` : attr.name
    if (filterBarType == FILTER_BAR_MAP.CLOUD_TAGS) { 
      const config = (filterBar?.filterConfig?.tagCloudSlotConfig || []).find(item => item.tagId == attr.id)
      const labelConfig = config?.displayContent?.labelLang || config?.tagName  || ''
      labelConfig && (label = (selectedNum && showNum) ? `${labelConfig}(${selectedNum})` : labelConfig)
    }

    return {
      ...attr,
      value: attr.id,
      label,
      selectedIds,
      selectedNum,
      active: selectedNum > 0,
      tagItemType: TAG_ITEM_TYPE.CUSTOM
    }
  })
}

export function handleParamsByTagCustom (params) {
  if (params.operation === 'reset') {
    const tspResults = handleParamsByTagTsp(params)
    const attrResults = handleParamsByTagAttrs(params)
    const brandResults = handleParamsByTagBrand(params)

    return {
      params: {
        ...tspResults.params,
        ...attrResults.params,
        ...brandResults.params,
      },
      filterResult: {
        isDelete: true,
        type: FILTER_RESULT_TYPE_MAP.CUSTOM,
        tspIds: tspResults.filterResult.ids,
        attrIds: attrResults.filterResult.ids,
        brandIds: brandResults.filterResult.ids,
      }
    }
  }

  const nodeType = params.curSelectedItem?.nodeType
  if (nodeType == 7) return handleParamsByTagTsp(params)
  if (nodeType == 9) return handleParamsByTagBrand(params)
  return handleParamsByTagAttrs(params)
  
}


import { formatMuitlData } from 'public/src/pages/components/filter_new/common/format'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { TAG_ITEM_TYPE, FILTER_BAR_MAP, FILTER_RESULT_TYPE_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'
import { getSourceInsideFilterOrCloudTagPanel } from './index.js'

// 获取当前选中的属性
export function getActiveBrandList  (queryParams) {
  return queryParams?.brand_ids?.split(',') || []
}

// 格式化品牌数据
export function formatBrandList (list) {
  if (!list?.length) return []

  // 只保留有数据的项
  list.forEach(attr => {
    attr.dataType = 'mulit'
    attr.data = attr.attr_values
  })

  return formatMuitlData({
    attr_id: 'id',
    attr_name: 'name',
    data: 'data',
    dataItem: {
      attr_id: 'id',
      attr_value: 'label',
      group_name: 'label',
      nodeId: 'mix_value',
    }
  }, list, false)
}

export function filterBrandByNodeType (attr) {
  return attr?.filter(item => item.nodeType == 8) ?? []
}

// 获取品牌数据
export function getBrandList ({ filterBar, queryParams, filterBarType, showNum = true }) {
  if (!filterBar?.filterData?.filterAttrs?.length) return []
  
  let list = filterBrandByNodeType(filterBar?.filterData?.filterAttrs)
  list = formatBrandList(list, filterBar)
  const activeList  = getActiveBrandList(queryParams)
  return list.map(attr => {
    const selectedIds = [] // 被选中的属性值个数
    attr.data.forEach(item => {
      activeList?.includes(item.nodeId) && selectedIds.push(item.nodeId)
    })
    const selectedNum = selectedIds.length
    let label = (selectedNum && showNum) ? `${attr.name}(${selectedNum})` : attr.name
    if (filterBarType == FILTER_BAR_MAP.CLOUD_TAGS) { 
      const config = (filterBar?.filterConfig?.tagCloudSlotConfig || []).find(item => item.tagId == attr.id)
      const labelConfig = config?.displayContent?.labelLang || config?.tagName  || ''
      labelConfig && (label = (selectedNum && showNum) ? `${labelConfig}(${selectedNum})` : labelConfig)
    }

    return {
      ...attr,
      value: attr.id,
      label,
      selectedIds,
      selectedNum,
      active: selectedNum > 0,
      tagItemType: TAG_ITEM_TYPE.BRAND
    }
  })
}

export function handleParamsByTagBrand({ data, clickType, curSelectedItem, operation, curSelectedNum, fhContext, googleContext, sourceFrom }) {
  const ids = []

  // brandTags 中选中的品牌
  data.forEach(item => {
    item.nodeType == 9 && !ids.includes(item.nodeId) && ids.push(item.nodeId)
  })


  // 埋点
  if (operation != 'reset') {
    analysis.clickAttr({
      clickType,
      curSelectedItem,
      selectedAttrs: ids.join('-'),
      facet: fhContext?.[`attr${curSelectedItem.id}`],
      gfilter: googleContext?.gfilter,
    })
  }

  const params = { 
    brand_ids: ids.join(','),
    ...getSourceInsideFilterOrCloudTagPanel({ sourceFrom, active: curSelectedItem.active })
  }
  
  return {
    params,
    filterResult: {
      ids,
      type: FILTER_RESULT_TYPE_MAP.BRAND,
      label: curSelectedItem.label,
      isDelete: !curSelectedItem.active,
      label_id: curSelectedItem.value,
    }
  }
}

